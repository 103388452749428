import { Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'

import { RequiredTextField } from 'components/common'
import TopNavigation from 'components/common/navigation/TopNavigation'
import PageTitle from 'components/common/PageTitle'
import { addUserWithCode } from 'utils/api/api'
import { useAuthState } from 'utils/hooks/useAuthState'
import { RequireAuth } from 'components/auth/RequireAuth'

import styles from './sign-up.module.scss'
import { logOut } from 'utils/auth'

const JoinLeague: React.FC = () => {
  const [leagueCode, setLeagueCode] = useState<string>('')
  const [errorText, setErrorText] = useState<string>()
  const [loading, setLoading] = useState(false)
  const { user } = useAuthState()

  const [params] = useSearchParams()
  const code = useMemo(() => params.get('code'), [params])

  async function handleUserSignUp () {
    try {
      const { error } = await addUserWithCode(user?.email ?? '', '', leagueCode)
      if (error) {
        throw new Error(error)
      } else {
        void logOut()
      }
    } catch (e: unknown) {
      console.error(e)
      setErrorText(e as string)
    } finally {
      setLoading(false)
    }
  }

  return (
    <RequireAuth ignoreAuthRedirect>
      <PageTitle title='Join a league' />
      <TopNavigation>
        <section className={styles.container}>
          <Typography variant='h5'>Join a League</Typography>
          <RequiredTextField autoComplete='off' value={params.get('code')} label='League code' type='password' onChange={(event) => { setLeagueCode(event.target.value) }} disabled={!!code} error={!code && !leagueCode} />
          <Typography style={{ textAlign: 'left' }}>You will be prompted to sign in again after joining the league.</Typography>
          <LoadingButton variant="contained" onClick={() => { void handleUserSignUp() }} loading={loading} disabled={!leagueCode}>Join</LoadingButton>
          {errorText && <p style={{ color: 'red' }}>Error signing up: {errorText}</p>}
        </section>
      </TopNavigation>
    </RequireAuth>
  )
}

export default JoinLeague
