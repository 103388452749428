import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CircularProgress,
  Divider,
  Backdrop,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'

import {
  type MASLReportSchema,
  DEFAULT_MASL_REPORT,
  type Review,
  type MASLTeam,
  type Penalty,
  type MASLQuestion,
  type MASLLeagueType,
  GameType
} from 'utils/constants/masl'
import { League } from 'utils/constants/constants'
import { validate } from 'utils'
import { useReport } from 'utils/hooks/useReport'
import {
  Text,
  LinkButton
} from 'components/common'
import ConfirmDialog from 'components/common/modal/ConfirmDialog'
import MASLRosters from 'components/masl/report-parts/MASLRosters'
import MASLOfficials from 'components/masl/report-parts/MASLOfficials'
import MASLTimes from 'components/masl/report-parts/MASLTimes'
import MASLScores from 'components/masl/report-parts/MASLScores'
import MASLPenalties from 'components/masl/report-parts/MASLPenalties'
import MASLReviews from 'components/masl/report-parts/MASLReviews'
import MASLNotes from 'components/masl/report-parts/MASLNotes'
import MASLUploadWorksheet from 'components/masl/report-parts/MASLUploadWorksheet'
import PageTitle from 'components/common/PageTitle'
import { useMobile } from 'utils/hooks/useMobile'
import LocaleTime from 'components/common/LocaleTime'

function validateAll (report?: MASLReportSchema): string[] {
  if (report?.gameType === GameType.EXTRA_TIME_PERIOD && !report.extraTimePlayed) return []

  const problems = []

  if (!validate(report?.date)) problems.push('Missing date')
  if (!validate(report?.location)) problems.push('Missing location')
  if (!validate(report?.homeTeam)) problems.push('Missing home team')
  if (!validate(report?.homeScore)) problems.push('Missing home score')
  if (!validate(report?.awayTeam)) problems.push('Missing away team')
  if (!validate(report?.awayScore)) problems.push('Missing away score')
  if (!validate(report?.seniorReferee)) problems.push('Missing senior referee')
  if (!validate(report?.referee)) problems.push('Missing referee')
  if (!validate(report?.assistantReferee)) problems.push('Missing AR')
  if (!validate(report?.fourthOfficial)) problems.push('Missing 4th')
  if (!validate(report?.otherOfficials)) problems.push('Missing other officials')
  if (!validate(report?.firstHalfStart)) problems.push('Missing 1st half start')
  if (!validate(report?.secondHalfStart)) problems.push('Missing 2nd half start')
  if (!validate(report?.gameEndTime)) problems.push('Missing game end time')
  if (!validate(report?.worksheetImageUrl)) problems.push('Missing worksheet image')
  if (report?.shootoutOccurred) {
    if (!validate(report?.homeShootoutScore)) problems.push('Missing home shootout score')
    if (!validate(report?.awayShootoutScore)) problems.push('Missing away shootout score')
  }
  for (const penalty of (report?.penalties ?? [])) {
    if (
      (penalty.playerType !== 'Team' && !validate(penalty.name)) ||
      (penalty.playerType === 'Player' && !validate(penalty.number)) ||
      !validate(penalty.period) ||
      !validate(penalty.team) ||
      !validate(penalty.time) ||
      !validate(penalty.code) ||
      !validate(penalty.explanation)
    ) problems.push('Missing penalty information')
  }
  for (const review of (report?.reviews ?? [])) {
    if (
      !validate(review.category) ||
      !validate(review.initiator) ||
      !validate(review.period) ||
      !validate(review.team) ||
      !validate(review.time) ||
      !validate(review.code)
    ) problems.push('Missing review information')
  }
  if (!validate(report?.homeRosterImageUrl)) problems.push('Missing home roster')
  if (!validate(report?.awayRosterImageUrl)) problems.push('Missing away roster')
  if ((report?.penalties ?? []).some(penalty => penalty?.code === 'Y17' || penalty?.code?.startsWith('R')) && (report?.notes ?? '') === '') {
    problems.push('Missing required notes for Y17 or Ejection')
  }

  return problems
}

export const MASLReport: React.FC<{ league: MASLLeagueType }> = ({ league }) => {
  const { isMobile } = useMobile()
  const navigate = useNavigate()
  const { id = '' } = useParams()
  const { report, loading, save, update, submit, saving, error } = useReport<MASLReportSchema>(league, id, DEFAULT_MASL_REPORT)

  const [showMissingInformation, setShowMissingInformation] = useState(false)
  const [missingInformation, setMissingInformation] = useState<string[]>([])
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false)

  const extraTimeGameNotPlayed = useMemo(() => report?.gameType === GameType.EXTRA_TIME_PERIOD && !report?.extraTimePlayed, [report?.extraTimePlayed, report?.gameType])

  const updateState = useCallback((key: keyof MASLReportSchema, value?: string | MASLTeam | Review[] | Penalty[] | boolean | null | MASLQuestion) => {
    if (value !== undefined) {
      update(prev => ({ ...prev, [key]: value }))
    }
  }, [update])

  useEffect(() => {
    if (!report?.editable) {
      updateState('editable', true)
    }
  }, [report?.editable, updateState])

  useEffect(() => {
    setShowMissingInformation(false)
  }, [report])

  function handleSubmit (): void {
    const messages = validateAll(report)

    const valid = messages.length === 0

    if (valid && (id != null)) {
      setShowMissingInformation(false)
      submit().then(() => {
        navigate(`/${league}/view/${id}`, { replace: true })
      }).catch((e) => {
        console.error(e)
      })
    } else {
      setMissingInformation(messages)
      setShowMissingInformation(true)
    }
    setConfirmSubmitOpen(false)
  }

  const menuBar = useMemo(() => (
    <section style={{ display: 'flex', marginBottom: 8, marginTop: 8, gap: 8, flexDirection: isMobile ? 'column' : 'row' }}>
      <LinkButton disabled={saving || loading || !report?.editable} to={`/${league}/view/${id}`} fullWidth={isMobile}>Preview</LinkButton>
      <LoadingButton loading={saving} variant='contained' disabled={loading || !report?.editable} onClick={() => { void save() }} fullWidth={isMobile}>Save</LoadingButton>
      <Button variant='contained' color='success' disabled={saving || loading || !report?.editable} onClick={() => { setConfirmSubmitOpen(true) }} fullWidth={isMobile}>Submit</Button>
    </section>
  ), [report, id, league, loading, saving, isMobile, save])

  if (loading) {
    return (
      <>
        <PageTitle title={`${league.toLocaleUpperCase()} - Loading Report`} />
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    )
  }

  if (error) {
    <Typography variant='h3' style={{ textAlign: 'center' }}>Error loading match report</Typography>
  }

  return (
    <>
      <PageTitle title={`${league.toLocaleUpperCase()} - Report: ${report?.gameNumber ?? ''}`} />
      <section style={{ display: 'flex', flexDirection: 'column', marginBottom: 8, marginTop: 8, gap: 8 }}>
        {menuBar}
        <Divider>Game</Divider>
        <section style={{ display: 'flex', gap: 8, alignItems: 'center', flexWrap: 'wrap' }}>
          <Text value={report?.gameNumber} label='Game Number' />
          <Divider orientation='vertical' flexItem />
          <Text
            value={new Date(report?.date ?? '').toLocaleDateString()}
            label='Game date'
          />
          <Divider orientation='vertical' flexItem />
          <Text value={<LocaleTime date={report?.date} time={report?.gameTime} />} label='Time' />
          <Divider orientation='vertical' flexItem />
          <Text value={report?.location} label='Location' />
          <Divider orientation='vertical' flexItem />
          <Text value={report?.homeTeam} label='Home' />
          <Divider orientation='vertical' flexItem />
          <Text value={report?.awayTeam} label='Away' />
        </section>
      </section>

      {!report?.id && <h1 style={{ textAlign: 'center' }}>Match Report does not exist</h1>}

      {report?.id && report?.gameType === GameType.EXTRA_TIME_PERIOD && (
         <article style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
          <Divider>Game status</Divider>
          <FormControl fullWidth>
            <FormLabel>Was the Extra Time Period played?</FormLabel>
            <RadioGroup
              value={report?.extraTimePlayed ? 'yes' : 'no'}
              onChange={(event) => { updateState('extraTimePlayed', event.target.value === 'yes') }}
            >
              <FormControlLabel value={'yes'} control={<Radio />} label="Yes" />
              <FormControlLabel value={'no'} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </article>
      )}

      {report?.id && !extraTimeGameNotPlayed && (
        <article style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
          <Divider>Rosters</Divider>
          <MASLRosters
            homeTeam={report?.homeTeam}
            homeRosterImageUrl={report?.homeRosterImageUrl}
            awayTeam={report?.awayTeam}
            awayRosterImageUrl={report?.awayRosterImageUrl}
            league={league}
            id={report?.id}
            onStateChange={updateState}
          />
          <Divider>Officials</Divider>
          <MASLOfficials
            onStateChange={updateState}
            seniorReferee={report?.seniorReferee}
            referee={report?.referee}
            assistantReferee={report?.assistantReferee}
            fourthOfficial={report?.fourthOfficial}
            otherOfficials={report?.otherOfficials}
            thirdReferee={report?.thirdReferee}
          />
          <Divider>Match Information</Divider>
          <MASLTimes
            firstHalfStart={report?.firstHalfStart}
            secondHalfStart={report?.secondHalfStart}
            gameEndTime={report?.gameEndTime}
            firstHalfStartExplanation={report?.firstHalfStartExplanation}
            secondHalfStartExplanation={report?.secondHalfStartExplanation}
            gameLengthTime={report?.gameLengthTime}
            onStateChange={updateState}
          />
          <Divider>Score</Divider>
          <MASLScores
            homeTeam={report?.homeTeam}
            awayTeam={report?.awayTeam}
            homeScore={report?.homeScore}
            awayScore={report?.awayScore}
            shootoutOccurred={report?.shootoutOccurred}
            homeShootoutScore={report?.homeShootoutScore}
            awayShootoutScore={report?.awayShootoutScore}
            overtimeOccurred={report?.overtimeOccurred}
            onStateChange={updateState}
          />
          <Divider>Penalties</Divider>
          <MASLPenalties
            homeTeam={report?.homeTeam}
            awayTeam={report?.awayTeam}
            penalties={report?.penalties}
            onStateChange={updateState}
            league={league}
            gameType={report?.gameType}
          />
          <Divider>Reviews</Divider>
          <MASLReviews
            reviews={report?.reviews}
            homeTeam={report?.homeTeam}
            awayTeam={report?.awayTeam}
            onStateChange={updateState}
            gameType={report?.gameType}
          />
          <Divider>Worksheet</Divider>
          <MASLUploadWorksheet
            onStateChange={updateState}
            id={id}
            worksheetImageUrl={report?.worksheetImageUrl}
            league={league}
          />
          <Divider>Notes</Divider>
          <MASLNotes
            league={league}
            notes={report?.notes}
            seniorRefereeDistance={report?.seniorRefereeDistance}
            refereeDistance={report?.refereeDistance}
            thirdRefereeDistance={report?.thirdRefereeDistance}
            onStateChange={updateState}
            leagueStandardsQuestions={report?.leagueStandardsQuestions}
            onQuestionChange={(question, answer, note) => {
              const prev = report?.leagueStandardsQuestions ?? {}
              prev[question] = { answer, note }
              updateState('leagueStandardsQuestions', prev)
            }}
          />
        </article>
      )}
      <Divider />
      {menuBar}
      <ConfirmDialog
        open={confirmSubmitOpen}
        title='Confirm submission'
        body={(
          <>
            <Typography>Are you sure you want to submit your report?? You can not edit it after submission.</Typography>
            {(!report?.penalties || report?.penalties?.length === 0) && <Typography color='red'>You have not logged any <u>penalties</u> for this match.</Typography>}
            {league === League.MASL && (!report?.reviews || report?.reviews?.length === 0) && <Typography color='red'>You have not logged any <u>video reviews</u> for this match.</Typography>}
          </>
        )}
        onOk={() => {
          handleSubmit()
        }}
        onCancel={() => {
          setConfirmSubmitOpen(false)
        }}
      />
      <Dialog open={showMissingInformation} fullWidth maxWidth='sm'>
        <DialogTitle>Report is not complete</DialogTitle>
        <Divider />
        <DialogContent>
          <section style={{ marginTop: 8 }}>
            {missingInformation.map((value, index) => (<Typography color='error' key={index}>{value}</Typography>))}
          </section>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" onClick={() => { setShowMissingInformation(false) }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
